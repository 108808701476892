
import HomePage from '../pages/home.f7.html';
import AboutPage from '../pages/about.f7.html';
import FormPage from '../pages/form.f7.html';
import documentLookup from '../pages/documentLookup.f7.html';
import VLGraph from '../pages/VL+Graph.f7.html';
import infoVoyDriver from '../pages/infoVoyDriver.f7.html';

import LeftPage1 from '../pages/left-page-1.f7.html';
import LeftPage2 from '../pages/left-page-2.f7.html';
import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';

var routes = [
  {
    path: '/',
    component: HomePage,
    on: {
      pageInit: function () {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has('token') && urlParams.has('mode')) {

        } else {
          var router = this;
          var app = router.app;
          var loginScreen = app.loginScreen.create({
            el: document.getElementById("my-login-screen")
          });
          loginScreen.open();
        }
      }
    }
  },
  {
    path: '/statsDriver/:driverUUID',
    component: VLGraph,
    options: {
      context: {
        pageTitle: 'Statistiques Trajet',
        VLID: 'statsDriver'
      },
      pushState: true
    },
    on: {
      pageInit: function () {
        var router = this;
        var app = router.app;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        app.request.get("https://api.omsi-gpm.fr/backend/?getDriverTripStats&uuid=" + router.currentRoute.params.driverUUID, function (data) {
          var items = [];
          var stopData = [];
          data = JSON.parse(data);
          console.log(data);
          data.forEach((item)=>{
            items.push({
              title:`${item.stopName}`,
              subtitle:`${item.stopTime} ${item.stopDelay}`,
              pax:item.stopPax
            });
            stopData.push([item.stopName, item.stopPax]);
          });

          var virtualList = app.virtualList.create({
            // List Element
            el: '.virtual-list-statsDriver',
            // Pass array with items
            items: items,
            // Custom search function for searchbar
            searchAll: function (query, items) {
              var found = [];
              for (var i = 0; i < items.length; i++) {
                if (items[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 || items[i].subtitle.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
              }
              return found; //return array with mathced indexes
            },
            // List item Template7 template
            itemTemplate:
                '<li>' +
                '<div class="item-content">' +
                '<div class="item-inner">' +
                '<div class="item-title-row">' +
                '<div class="item-title">{{title}}</div>' +
                '<div class="item-after">' +
                '<span class="badge color-primary">{{pax}} passagers</span>' +
                '</div>' +
                '</div>' +
                // '<div class="item-subtitle">{{subtitle}}</div>' +
                '</div>' +
                '</div>' +
                '</li>',
            // Item height
            height: app.theme === 'ios' ? 64 : (app.theme === 'md' ? 73 : 46),
          });


          // Load the Visualization API and the corechart package.
          google.charts.load('current', {'packages':['corechart', 'bar']});

          // Set a callback to run when the Google Visualization API is loaded.
          google.charts.setOnLoadCallback(drawChart);

          // Callback that creates and populates a data table,
          // instantiates the pie chart, passes in the data and
          // draws it.
          function drawChart() {

            // Create the data table.
            var data = new google.visualization.DataTable();
            data.addColumn('string', 'Arret');
            data.addColumn('number', 'Passagers');
            data.addRows(stopData);

            // Set chart options
            var options = {'title':'Passagers aux arrets du trajet',
              'width':"75%",
              'height':"auto",
              'hAxis':{
                'minValue':0
              },
              'bars': 'horizontal',
              'colors': ['#ea750e']
            };

            // Instantiate and draw our chart, passing in some options.
            var chart = new google.charts.Bar(document.getElementById('chart_div-statsDriver'));
            chart.draw(data, options);
          }
        });

      }
    }
  },
  {
    path: '/infoVoyDriver/:driverUUID',
    component: infoVoyDriver,
    on: {
      pageInit: function () {
        var router = this;
        var app = router.app;
        console.log(router.currentRoute.params.driverUUID);
        var canvas = document.getElementById("matrixTmp");
        var ctx = canvas.getContext('2d');
        var img = new Image;
        img.crossOrigin = "Anonymous";
        img.onload = function(){
          ctx.drawImage(img,0,0); // Or at whatever offset you like
        };
        img.src = "https://api.omsi-gpm.fr/backend/girouettes/?uuid=762be979-ac73-4a60-bee5-c58cbb1256cf&type=Mobitec_GPMS";
        document.getElementById("infVoyPage").setAttribute("uuid", router.currentRoute.params.driverUUID);
        document.getElementById("frontGir").onkeyup = ()=>{document.getElementById("codeGir").value = 989};
        document.getElementById("sideGir").onkeyup = ()=>{document.getElementById("codeGir").value = 989};
        document.getElementById("lineGir").onchange = ()=>{document.getElementById("codeGir").value = 989};
      }
    }
  },
  {
    path: '/stats/',
    component: documentLookup,
    options: {
      context: {
        pageTitle: 'Recherche Conducteur',
        VLID: 'stats'
      },
      pushState: true
    },
    on: {
      pageInit: function () {
        var router = this;
        var app = router.app;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        app.request.get("https://api.omsi-gpm.fr/backend/?getCompanyDriversFromToken&token=" + urlParams.get('token'), function (data) {
          var items = [];
          data = JSON.parse(data);
          data.forEach((item)=>{
            items.push({
              title:`${item.userName} (${item.userID})`,
              subtitle:`Ligne: ${item.tripLineName} Véhicule: ${item.tripVehicle}`,
              uuid:item.userUUID,
              lastUpdate:item.tripLastUpdate,
              disabled: (Math.abs((new Date())-(new Date(item.tripLastUpdate)))>15) ? "disabled" : ""
            });
          });

          var virtualList = app.virtualList.create({
            // List Element
            el: '.virtual-list-stats',
            // Pass array with items
            items: items,
            // Custom search function for searchbar
            searchAll: function (query, items) {
              var found = [];
              for (var i = 0; i < items.length; i++) {
                if (items[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 || items[i].subtitle.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
              }
              return found; //return array with mathced indexes
            },
            // List item Template7 template
            itemTemplate:
                '<li>' +
                '<a href="/statsDriver/{{uuid}}" class="item-link item-content {{disabled}}">' +
                '<div class="item-inner">' +
                '<div class="item-title-row">' +
                '<div class="item-title">{{title}}</div>' +
                '<div class="item-after">{{lastUpdate}}</div>' +
                '</div>' +
                '<div class="item-subtitle">{{subtitle}}</div>' +
                '</div>' +
                '</a>' +
                '</li>',
            // Item height
            height: app.theme === 'ios' ? 64 : (app.theme === 'md' ? 73 : 46),
          });
        });

      }
    }
  },
  {
    path: '/docsConducteurs/',
    component: documentLookup,
    options: {
      context: {
        pageTitle: 'Recherche Conducteur',
        VLID: 'docsConducteurs'
      },
      pushState: true
    },
    on: {
      pageInit: function () {
        var router = this;
        var app = router.app;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        app.request.get("https://api.omsi-gpm.fr/backend/?getCompanyDriversFromToken&token=" + urlParams.get('token'), function (data) {
          var items = [];
          data = JSON.parse(data);
          data.forEach((item)=>{
            items.push({
              title:`${item.userName} (${item.userID})`,
              // subtitle:`Ligne: ${item.tripLineName} Véhicule: ${item.tripVehicle}`,
              uuid:item.userUUID,
              // lastUpdate:item.tripLastUpdate
            });
          });

          var virtualList = app.virtualList.create({
            // List Element
            el: '.virtual-list-docsConducteurs',
            // Pass array with items
            items: items,
            // Custom search function for searchbar
            searchAll: function (query, items) {
              var found = [];
              for (var i = 0; i < items.length; i++) {
                if (items[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 || items[i].subtitle.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
              }
              return found; //return array with mathced indexes
            },
            // List item Template7 template
            itemTemplate:
                '<li>' +
                '<a href="https://api.omsi-gpm.fr/backend/userPDFs/?user={{uuid}}" class="external item-link item-content">' +
                '<div class="item-inner">' +
                '<div class="item-title-row">' +
                '<div class="item-title">{{title}}</div>' +
                '</div>' +
                '</div>' +
                '</a>' +
                '</li>',
            // Item height
            height: app.theme === 'ios' ? 64 : (app.theme === 'md' ? 73 : 46),
          });
        });

      }
    }
  },
  {
    path: '/infoVoy/',
    component: documentLookup,
    options: {
      context: {
        pageTitle: 'Recherche Conducteur',
        VLID: 'infoVoy'
      },
      pushState: true
    },
    on: {
      pageInit: function () {
        var router = this;
        var app = router.app;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        app.request.get("https://api.omsi-gpm.fr/backend/?getCompanyDriversFromToken&token=" + urlParams.get('token'), function (data) {
          var items = [];
          data = JSON.parse(data);
          data.forEach((item)=>{
            items.push({
              title:`${item.userName} (${item.userID})`,
              subtitle:`Ligne: ${item.tripLineName} Véhicule: ${item.tripVehicle}`,
              uuid:item.userUUID,
              lastUpdate:item.tripLastUpdate,
              // disabled: (Math.abs((new Date())-(new Date(item.tripLastUpdate)))>15) ? "disabled" : ""
              disabled: ""
            });
          });

          var virtualList = app.virtualList.create({
            // List Element
            el: '.virtual-list-infoVoy',
            // Pass array with items
            items: items,
            // Custom search function for searchbar
            searchAll: function (query, items) {
              var found = [];
              for (var i = 0; i < items.length; i++) {
                if (items[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 || items[i].subtitle.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
              }
              return found; //return array with mathced indexes
            },
            // List item Template7 template
            itemTemplate:
                '<li>' +
                '<a href="/infoVoyDriver/{{uuid}}" class="item-link item-content {{disabled}}">' +
                '<div class="item-inner">' +
                '<div class="item-title-row">' +
                '<div class="item-title">{{title}}</div>' +
                '<div class="item-after">{{lastUpdate}}</div>' +
                '</div>' +
                '<div class="item-subtitle">{{subtitle}}</div>' +
                '</div>' +
                '</a>' +
                '</li>',
            // Item height
            height: app.theme === 'ios' ? 64 : (app.theme === 'md' ? 73 : 46),
          });
        });

      }
    }
  },
  {
    path: '/fichesHoraires/',
    component: documentLookup,
    options: {
      context: {
        pageTitle: 'Fiches Horaires',
        VLID: 'fichesHoraires'
      },
      pushState: true
    },
    on: {
      pageInit: function () {
        var router = this;
        var app = router.app;
        app.request.get("https://api.omsi-gpm.fr/backend/pdfs.json", function (data) {
          var items = [];
          data = JSON.parse(data);
          data.files.fichesHoraires.forEach((item)=>{
            var lines = "";
            for (var j = 0; j < item.lines.length; j++) {
              var line = fancyLine(item.lines[j]);
              lines += "<span class='line' style='background: #" + line.back + "; color: #" + line.front + "'> " + line.text + "</span>  ";
            }
            lines = lines.slice(0, -2);
            items.push({
              title:item.url.substring(item.url.lastIndexOf('/')+1),
              subtitle:lines,
              link:item.url
            });
          });

          var virtualList = app.virtualList.create({
            // List Element
            el: '.virtual-list-fichesHoraires',
            // Pass array with items
            items: items,
            // Custom search function for searchbar
            searchAll: function (query, items) {
              var found = [];
              for (var i = 0; i < items.length; i++) {
                if (items[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 || items[i].subtitle.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
              }
              return found; //return array with mathced indexes
            },
            // List item Template7 template
            itemTemplate:
                '<li>' +
                '<a href="{{link}}" class="external item-link item-content">' +
                '<div class="item-inner">' +
                '<div class="item-title-row">' +
                '<div class="item-title">{{title}}</div>' +
                '</div>' +
                '<div class="item-subtitle">{{subtitle}}</div>' +
                '</div>' +
                '</a>' +
                '</li>',
            // Item height
            height: app.theme === 'ios' ? 64 : (app.theme === 'md' ? 73 : 46),
          });
        });

      }
    }
  },
  {
    path: '/feuillesConducteurs/',
    component: documentLookup,
    options: {
      context: {
        pageTitle: 'Feuilles Conducteurs',
        VLID: 'feuillesConducteurs'
      },
      pushState: true
    },
    on: {
      pageInit: function () {
        var router = this;
        var app = router.app;
        app.request.get("https://api.omsi-gpm.fr/backend/pdfs.json", function (data) {
          var items = [];
          data = JSON.parse(data);
          data.files.InterLCM.forEach((item)=>{
            var lines = "";
            for (var j = 0; j < item.lines.length; j++) {
              var line = fancyLine(item.lines[j]);
              lines += "<span class='line' style='background: #" + line.back + "; color: #" + line.front + "'> " + line.text + "</span>  ";
            }
            lines = lines.slice(0, -2);
            items.push({
              title:item.url.substring(item.url.lastIndexOf('/')+1),
              subtitle:lines,
              directory:'InterLCM',
              link:item.url
            });
          });
          data.files.GPMSLCM.forEach((item)=>{
            var lines = "";
            for (var j = 0; j < item.lines.length; j++) {
              var line = fancyLine(item.lines[j]);
              lines += "<span class='line' style='background: #" + line.back + "; color: #" + line.front + "'> " + line.text + "</span>  ";
            }
            lines = lines.slice(0, -2);
            items.push({
              title:item.url.substring(item.url.lastIndexOf('/')+1),
              subtitle:lines,
              directory:'GPMSLCM',
              link:item.url
            });
          });
          data.files.CarsVier.forEach((item)=>{
            var lines = "";
            for (var j = 0; j < item.lines.length; j++) {
              var line = fancyLine(item.lines[j]);
              lines += "<span class='line' style='background: #" + line.back + "; color: #" + line.front + "'> " + line.text + "</span>  ";
            }
            lines = lines.slice(0, -2);
            items.push({
              title:item.url.substring(item.url.lastIndexOf('/')+1),
              subtitle:lines,
              directory:'CarsVier',
              link:item.url
            });
          });
          data.files.CarsVierNav.forEach((item)=>{
            var lines = "";
            for (var j = 0; j < item.lines.length; j++) {
              var line = fancyLine(item.lines[j]);
              lines += "<span class='line' style='background: #" + line.back + "; color: #" + line.front + "'> " + line.text + "</span>  ";
            }
            lines = lines.slice(0, -2);
            items.push({
              title:item.url.substring(item.url.lastIndexOf('/')+1),
              subtitle:lines,
              directory:'CarsVierNav',
              link:item.url
            });
          });
          data.files.keolis.forEach((item)=>{
            var lines = "";
            for (var j = 0; j < item.lines.length; j++) {
              var line = fancyLine(item.lines[j]);
              lines += "<span class='line' style='background: #" + line.back + "; color: #" + line.front + "'> " + line.text + "</span>  ";
            }
            lines = lines.slice(0, -2);
            items.push({
              title:item.url.substring(item.url.lastIndexOf('/')+1),
              subtitle:lines,
              directory:'keolis',
              link:item.url
            });
          });
          data.files.plansGare.forEach((item)=>{
            var lines = "";
            for (var j = 0; j < item.lines.length; j++) {
              var line = fancyLine(item.lines[j]);
              lines += "<span class='line' style='background: #" + line.back + "; color: #" + line.front + "'> " + line.text + "</span>  ";
            }
            lines = lines.slice(0, -2);
            items.push({
              title:item.url.substring(item.url.lastIndexOf('/')+1),
              subtitle:lines,
              directory:'plansGare',
              link:item.url
            });
          });
          data.files.codesGirouettes.forEach((item)=>{
            var lines = "";
            for (var j = 0; j < item.lines.length; j++) {
              var line = fancyLine(item.lines[j]);
              lines += "<span class='line' style='background: #" + line.back + "; color: #" + line.front + "'> " + line.text + "</span>  ";
            }
            lines = lines.slice(0, -2);
            items.push({
              title:item.url.substring(item.url.lastIndexOf('/')+1),
              subtitle:lines,
              directory:'codesGirouettes',
              link:item.url
            });
          });

          var virtualList = app.virtualList.create({
            // List Element
            el: '.virtual-list-feuillesConducteurs',
            // Pass array with items
            items: items,
            // Custom search function for searchbar
            searchAll: function (query, items) {
              var found = [];
              for (var i = 0; i < items.length; i++) {
                if (items[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 || items[i].subtitle.toLowerCase().indexOf(query.toLowerCase()) >= 0 || items[i].directory.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
              }
              return found; //return array with mathced indexes
            },
            // List item Template7 template
            itemTemplate:
                '<li>' +
                '<a href="{{link}}" class="external item-link item-content">' +
                '<div class="item-inner">' +
                '<div class="item-title-row">' +
                '<div class="item-title">{{title}}</div>' +
                '</div>' +
                '<div class="item-subtitle">{{subtitle}}</div>' +
                '</div>' +
                '</a>' +
                '</li>',
            // Item height
            height: app.theme === 'ios' ? 64 : (app.theme === 'md' ? 73 : 46),
          });
        });

      }
    }
  },
  {
    path: '/form/',
    component: FormPage,
  },

  {
    path: '/left-page-1/',
    component: LeftPage1,
  },
  {
    path: '/left-page-2/',
    component: LeftPage2,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;

function fancyLine(line) {
  var res = {
    "text":line,
    "back":"000000",
    "front":"ffffff"
  };

  switch (line) {
    case "9106a":
      res = {
        "text":"91.06A",
        "back":"52B46E",
        "front":"ffffff"
      };
      break;
    case "9106b":
      res = {
        "text":"91.06B",
        "back":"A47F5C",
        "front":"ffffff"
      };
      break;
    case "9106c":
      res = {
        "text":"91.06C",
        "back":"EB671A",
        "front":"ffffff"
      };
      break;
    case "9106d":
      res = {
        "text":"91.06D",
        "back":"41BFEF",
        "front":"ffffff"
      };
      break;
    case "7":
      res = {
        "text":"7",
        "back":"26468F",
        "front":"ffffff"
      };
      break;
    case "14":
      res = {
        "text":"14",
        "back":"BA3381",
        "front":"ffffff"
      };
      break;
    case "8":
      res = {
        "text":"8",
        "back":"DEBB25",
        "front":"ffffff"
      };
      break;
    case "s8":
      res = {
        "text":"s8",
        "back":"DEBB25",
        "front":"ffffff"
      };
      break;
    case "11":
      res = {
        "text":"11",
        "back":"40A7BD",
        "front":"ffffff"
      };
      break;
    case "11s":
      res = {
        "text":"11s",
        "back":"40A7BD",
        "front":"ffffff"
      };
      break;
    case "17":
      res = {
        "text":"17",
        "back":"94C140",
        "front":"ffffff"
      };
      break;
    case "17s":
      res = {
        "text":"17s",
        "back":"94C140",
        "front":"ffffff"
      };
      break;
    case "H":
      res = {
        "text":"H",
        "back":"3E94BE",
        "front":"ffffff"
      };
      break;
    case "9110":
      res = {
        "text":"91.10",
        "back":"6BADDF",
        "front":"ffffff"
      };
      break;
    case "9":
      res = {
        "text":"9",
        "back":"F2662A",
        "front":"ffffff"
      };
      break;

  }
  return res;
}