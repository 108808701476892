export default {
  id: '06985d356f',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class=title>';
      r += c(ctx_1.pageTitle, ctx_1);
      r += '</div><!--        <div class="right">--><!--          <a href="#" class="link icon-only panel-open" data-panel="right">--><!--            <i class="icon f7-icons if-not-md">menu</i>--><!--            <i class="icon material-icons if-md">menu</i>--><!--          </a>--><!--        </div>--><div class=subnavbar><form data-search-container=.virtual-list-';
      r += c(ctx_1.VLID, ctx_1);
      r += ' data-search-item=li data-search-in=.item-title class="searchbar searchbar-init"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Recherche> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Annuler</span></div></form></div></div></div><!-- Toolbar--><div class="toolbar tabbar toolbar-bottom"><div class=toolbar-inner><a href=#tab-1 class="tab-link tab-link-active">Liste</a> <a href=#tab-2 class=tab-link>Graph</a></div></div><div class=tabs-swipeable-wrap><div class=tabs><div id=tab-1 class="page-content tab tab-active"><div class=searchbar-backdrop></div><div class="list simple-list searchbar-not-found"><ul><li>Aucun Résultat Trouvé</li></ul></div><div class="list virtual-list-';
      r += c(ctx_1.VLID, ctx_1);
      r += ' media-list searchbar-found"></div></div><div id=tab-2 class="page-content tab"><div id=chart_div-';
      r += c(ctx_1.VLID, ctx_1);
      r += '></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};